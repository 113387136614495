import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => (props.size == 'small' ? 65 : 150),
    width: '100%',
    marginBottom: '20px',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.topRedBar.backgroundColor,
  },
  logoImg: {
    //filter: theme.palette.topRedBar.logoImgFilter,
    width: 150,
  },
  logoContainer: {
    position: 'absolute',
    top: (props) => (props.size == 'small' ? 9 : 95),
  },
  left: {
    left: '25px',
  },
  right: {
    right: '25px',
  },
}));

function TopRedBar(props) {
  const { size = 'default', darkMode, logoPosition = 'right' } = props;
  const classes = useStyles({ size: size });

  const logoImageUrl = useMemo(() => '/images/Multigate_Logo.svg', []);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.logoContainer, logoPosition == 'right' && classes.right, logoPosition == 'left' && classes.left)}>
        <img src={logoImageUrl} alt="logo" className={classes.logoImg} />
      </div>
    </div>
  );
}

export default TopRedBar;

TopRedBar.propTypes = {
  size: PropTypes.oneOf(['small', 'default']),
  darkMode: PropTypes.bool,
  logoPosition: PropTypes.oneOf(['left', 'right']),
};
